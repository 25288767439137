<template lang="pug">
div()
  v-list(subheader two-line)
    div(
      v-for='(item, i) in itemsSorted'
      :key='item._id'
    )
      v-list-tile(
        v-if="item.schedType === 'line'"
        avatar
      ).list-item
        v-list-tile-avatar(v-if="item.dashboard.image.url" tile @click="select(item)")
          img(:src="item.dashboard.image.url")
        v-list-tile-content(@click="select(item)")
          v-list-tile-title
            slot(name="line1" :item="item")
          v-list-tile-sub-title
            slot(name="line2" :item="item")
        
      v-list-tile.list-item.pink.lighten-5(
        v-if="item.schedType === 'date'"
      )
        v-list-tile-content
          v-list-tile-title.font-weight-black.title
            | {{ item.date }}

</template>
<script>
import moment from 'moment'
import PriorityPicker from './priority-picker'

export default {
  components: { PriorityPicker },
  props: {
    items: { type: Array },
  },
  computed: {
    itemsSorted () {
      let itemsSorted = this.items.sort((a, b) => {
        const A = a.dateProduction
        const B = b.dateProduction
        if (A > B) return 1
        if (A < B) return -1
        const C = a.dashboard.priority.production
        const D = b.dashboard.priority.production
        if (C < D) return 1
        if (C > D) return -1
        return 0
      })
      
      itemsSorted.map(item => {
        item.schedType = 'line'
      })

      // prepare date subsummary lines
      // account for an array of a single item
      if(itemsSorted.length <= 1) { return itemsSorted }
      // more than one item, start at second to last
      let previousItem = itemsSorted[itemsSorted.length - 1]
      for (let i = itemsSorted.length - 2; i >= 0; --i) {
        const previousDate = previousItem.dates.schedule
        const currentDate = itemsSorted[i].dates.schedule
        // if the date has changed from a previous item splice in a date line
        if (currentDate !== previousDate) {
          itemsSorted.splice(i + 1, 0, { schedType: 'date', date: moment(previousDate).format('LL') })
        }
        // if the last item is reached check to see if those items are unscheduled
        if (i === 0 && currentDate === '') {
          itemsSorted.splice(0, 0, { schedType: 'date', date: 'unscheduled:' })
        } else if (i === 0 && currentDate !== '') {
          itemsSorted.splice(0, 0, { schedType: 'date', date: moment(currentDate).format('LL') })
        }
        // set previous item to current item
        previousItem = itemsSorted[i]
      }
      return itemsSorted
    }
  },
  methods: {
    select (item) { this.$emit('select', item) },
  }
}
</script>
